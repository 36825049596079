/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  width: 98.33333333%;
  max-width: 1180px;
}
.navigation__desk {
  width: 100%;
  max-width: none;
  position: relative;
}
.cb-plugin-ganzboats--legend {
  margin-top: 30px;
}
.cb-plugin-ganzboats--legend-item {
  margin-top: 0;
  width: 33.33333333%;
}
h1,
.unit h2,
h5 {
  font-size: 18px;
  font-size: 1.8rem;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.42016807%;
  margin-left: 0.42016807%;
}
.area .part,
.area > .grid table {
  margin-right: 0.42016807%;
  margin-left: 0.42016807%;
  width: 99.15966387%;
}
.area .tiny {
  width: 49.15966387%;
}
.area > .slim {
  width: 25%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 1.68067227%;
  margin-left: 1.68067227%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96.63865546%;
}
.area > .slim .tiny {
  width: 96.63865546%;
}
.main > .wide {
  width: 50%;
}
.main > .wide h2,
.main > .wide .part,
.main > .wide.grid table,
.main > .wide .foot {
  margin-right: 0.84033613%;
  margin-left: 0.84033613%;
}
.main .wide .tall,
.main .wide.grid table {
  width: 98.31932773%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side h2,
.side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.side .part,
.side > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 100%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0.42016807%;
  margin-left: 0.42016807%;
  width: 99.15966387%;
}
.base h2,
.base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.base .part,
.base > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base .tiny {
  width: 50%;
}
.base > .slim {
  width: 49.15966387%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 100%;
}
.base > .slim .tiny {
  width: 100%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0.42016807%;
  margin-left: 0.42016807%;
  width: 99.15966387%;
}
.south h2,
.south .foot {
  margin-right: 1.01694915%;
  margin-left: 1.01694915%;
}
.south .part,
.south > .grid table {
  margin-right: 1.01694915%;
  margin-left: 1.01694915%;
  width: 97.96610169%;
}
.south .tiny {
  width: 47.96610169%;
}
.south > .slim {
  width: 24.15966387%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 4.17391304%;
  margin-left: 4.17391304%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 91.65217391%;
}
.south > .slim .tiny {
  width: 91.65217391%;
}
.area {
  margin-left: -0.42372881%;
  width: 100.84745763%;
}
.main {
  margin-top: 20px;
}
.link.mail {
  background-position: 0 0.5em;
}
.main > .slim.edge h2 {
  padding: 0 10px;
  font-size: 16px;
  font-size: 1.6rem;
}
@media (min-width: 840px) {
  .main > .slim.edge h2 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
#view .main > .edge.slim.note h2 {
  bottom: 0px;
}
@media (min-width: 840px) {
  #view .main > .edge.slim.note h2 {
    bottom: 10px;
  }
}
#view .main > .edge.slim.note h2 > .open {
  position: relative;
  -webkit-transform: translateZ(0);
  will-change: left,top;
}
#view .main > .edge.slim.note h2 > .open:hover,
#view .main > .edge.slim.note h2 > .open:focus {
  -webkit-transition: all 0.14s;
  -o-transition: all 0.14s;
  -moz-transition: all 0.14s;
  transition: all 0.14s;
  text-shadow: 2px 1px 0 #58c1f3, 4px 2px 0 #58c1f3, 6px 3px 0 #58c1f3, 8px 4px 0 #58c1f3, 10px 5px 0 #58c1f3, 12px 6px 0 #58c1f3, 14px 7px 0 #58c1f3, 16px 8px 0 #58c1f3, 18px 9px 0 #58c1f3, 20px 10px 0 #58c1f3;
  left: -1px;
  top: -1px;
}
#view .main > .edge.slim.note h2 > .open:active {
  color: #181c20;
}
.side > .pure .part {
  padding-left: 1.69491525%;
}
.side > .edge .part {
  max-width: 96%;
}
.side .norm,
.side .pale {
  font-size: 24px;
  font-size: 2.4rem;
}
@media (max-width: 1000px) {
  .edge.wide.note:before {
    right: 0.42372881%;
    left: 0.42372881%;
  }
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #454f59;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #454f59;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #2f363c;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #3a424b #24292e #24292e #3a424b;
  border-radius: 2px;
  background-color: #454f59;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(24, 28, 32, 0.75);
  line-height: 2.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #3a424b #24292e #24292e #3a424b;
  background-color: #4c5762;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #3a424b #24292e #24292e #3a424b;
  background-color: #2f363c;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-medium.css.map */